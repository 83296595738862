.map {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
}

.section-a {
    position: relative;
    float: left;
    width: 100%;
    height: 80px;
}

.section-b {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100% - 80px);
}

@media screen and (orientation:landscape) and (min-width: 1000px) {

    .section-a {
        position: relative;
        float: right;
        width: 320px;
        height: 100%;
    }
    
    .section-b {
        position: relative;
        float: left;
        width: calc(100% - 320px);
        height: 100%;
    }
}