.filter {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    margin-top: 50px;
    margin-left: 20px;
}

.filter-checkbox-container {
    position: relative;
    float: left;
    width: calc(100% - 8px);
    padding: 4px;
}

.filter-checkbox {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    padding: 0px;
    margin: 0px;
    margin-left: 20px;
}

.filter-checkbox-label {
    height: 24px;
    margin: 0px;
    padding: 0px;
    line-height: 24px;
    vertical-align: middle;
    margin-left: 5px;
}