.about-container {
    position: relative;
    float: left;
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-top: 50px;
}

.about-title {
    margin-bottom: 20px;
}

.about-text {
    position: relative;
    float: left;
    width: 100%;
    text-align: justify;
    margin: 0px;
    padding: 0px;   
}

.about-eddb-link {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

@media screen and (min-width: 700px) {
    .about-container {
        width: 600px;
        margin-left: calc(50% - 300px);
    }
}