.sidebar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #111;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.6);
    font-size: 90%;
}

.faction-search {
    position: relative;
    float: left;
    width: calc(100% - 20px);
    padding: 10px;
    padding-top: 10px;
}

.faction-search:hover {
    opacity: 1;
}

button, input {
    font-size: 120%;
}

.sidebar-black-cover {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.6);
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (orientation:landscape) and (min-width: 1000px) {

    .sidebar {
        bottom: 0px;
        right: 0px;
        left: initial;
        width: 320px;
        max-height: initial;
        height: initial;
        overflow: initial;
        z-index: 800;
    }
}