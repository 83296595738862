.allegiance-badge {
    position: relative;
    float: left;
}

.allegiance-badge img {
    position: relative;
    float: left;
    width: 25px;
    height: auto;
}

.allegiance-badge p {
    position: relative;
    float: left;
    margin: 3px 0px 3px 3px;
}