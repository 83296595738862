@font-face{
  font-family: 'Fira Sans';
  src: url('assets/fonts/FiraSans-Regular.otf') format('opentype');
}

@font-face{
  font-family: 'Fira Sans';
  font-weight: bold;
  src: url('assets/fonts/FiraSans-Bold.otf') format('opentype');
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: #000;
}

/**
*** Default typesetting for all text elements
**/
body, input, button, hr, p, b, strong, h1, h2, h3, h4, h5, h6, a, a:visited {
  font-family: 'Fira Sans';
  text-shadow: 1px 1px 10px #cd0d00;
  text-transform: uppercase;
  color: #e48c58;
  border-color: #e48c58;
}

/**
*** Default typesetting for all non-header text elements
**/
body, input, button, hr, p, b, strong {
  font-size: 12px;
}

/**
*** Default typesetting for all header text elements
**/
h1, h2, h3, h4, h5, h6 {
  padding: 5px;
  margin: 5px;
  width: calc(100% - 20px);
  text-shadow: 1px 1px 20px #db4105;
  border-radius: 3px;
  text-align: center;
}

h6 {
  font-size: 110%;
}

h5 {
  font-size: 120%;
}

h4 {
  font-size: 130%;
}

h3 {
  font-size: 140%;
}

h2 {
  font-size: 150%;
}

h1 {
  font-size: 160%;
}

/**
*** Default horizontal rule style
**/
hr {
  position: relative;
  float: left;
  width: 100%;
  border-width: 2px;
  border-right: none;
  border-left: none;
  border-top: none;
}

/**
*** Default button style
**/
button, input {
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  text-align: center;
  outline: none;
}

input, .has-input-background {
  background: #000;
}

button {
  width: 100%;
}

button, input[type=button], input[type=checkbox], .has-button-background {
  background: #280a00;
}

button:hover, 
button.selected:hover,
button.selected:hover p,
input[type=button]:hover, 
input[type=button].selected:hover, 
.clickable:hover, 
.clickable.selected {
  cursor: pointer;
  color: #000;
  background: #E48C58;
}

button.selected, input[type=button].selected, button.selected p {
  color: #000;
  background: #C77342;
}

.emphasize {
  font-weight: bold;
  font-size: 120%;
}

.has-primary-background {
  background: #E48C58;
}

.has-conflict-animation {
  background: rgba(164, 6, 73, 1);
  animation-name: conflict-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.desktop-only {
  display: none;
}

a:link, a:visited, a:hover {
  color: inherit;
}

a:link, a:visited {
  text-decoration: inherit;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

@keyframes conflict-animation {
  0% { background: rgba(164, 6, 73, 0); }
  50% { background: rgba(164, 6, 73, 1); }
  100% { background: rgba(164, 6, 73, 0); }
}

@media screen and (orientation:landscape) and (min-width: 1000px) {

  /**
  *** Larger font on desktop mode, this scales all text elements
  **/
  body, input, button, hr, p, b, strong {
    font-size: 14px;
  }

  input, button {
    padding: 5px;
  }

  input {
    width: calc(100% - 10px);
  }

  .desktop-only {
    display: block;
  }
}
