.system-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 50;
    overflow-y: auto;
}

.system-view-window {
    position: relative;
    float: left;
    width: calc(100% - 60px);
    height: initial;
    margin: 10px;
    padding: 20px;
    background: #111;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.6);
}

.system-view-name {
    text-align: center;
    margin-top: 0px;
}

.system-view-updated-at {
    font-size: 80%;
    margin: 10px 0px 10px 0px;
    padding: 0px;
    width: 100%;
    text-align: center;
}

.system-view-navigation {
    position: relative;
    float: left;
    width: 100%;
}

.system-view-navigation button {
    width: 25%;
    font-size: 90%;
}

.system-view-announcements, .system-view-announcement {
    position: relative;
    float: left;
    width: 100%;
}

.system-view-announcements {
    width: calc(100% - 40px);
    padding: 0px 0px 0px 40px;
}

.system-view-announcement {
    padding: 10px 0px 10px 0px;
}

.system-view-announcement-title {
    position: relative;
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.system-view-announcement-subtext {
    font-size: 80%;
}

.system-view-capital-ships {
    position: relative;
    float: left;
    width: 100%;
}

.system-view-capital-ships-image {
    position: relative;
    float: left;
    width: 50%;
}

.system-view-capital-ships-note {
    position: relative;
    float: right;
    width: calc(50% - 40px);
    margin: 20px 20px 0px 20px;
    text-align: justify;
}

.system-view-capital-ships-note, .system-view-capital-ships-note .emphasize {
    color: #d91719;
}

.system-view-capital-ships-note-factions {
    position: relative;
    float: left;
    width: 100%;
    text-align: left;
    padding: 0px;
}

.system-view-capital-ships-note-faction {
    padding: 0px;
    font-weight: bold;
}

.system-view-close {
    margin-top: 10px;
}

@media screen and (orientation:landscape) and (min-width: 1000px) {

    .system-view-window {
        width: 620px;
        padding: 20px 40px 20px 40px;
        margin: 0px;
        margin-left: calc(50% - 350px);
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .system-view-close {
        width: 300px;
        margin-left: calc(50% - 155px);
    }

    .system-view-capital-ships-note-factions {
        padding: initial;
    }

    .system-view-navigation button {
        font-size: 100%;
    }

    @media screen and (orientation:landscape) and (min-width: 1100px) {
        
        .system-view-window {
            width: 720px;
            margin-left: calc(50% - 400px);
        }
    }
}