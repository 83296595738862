.faction-roster {
    position: relative;
    float: left;
    width: 100%;
}

.roster-faction {
    position: relative;
    float: left;
    width: calc(100% - 20px);
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 20px;
}

.roster-faction-allegiance {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    margin-top: 6px;
    margin-right: 2px;
}

.roster-faction-glowable {
    position: relative;
    float: left;
    width: calc(100% - 38px);
    padding: 6px;
}

.roster-faction-influence {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 2px;
    background: #FFF;
    min-width: 10px;
}

.roster-faction-influence-growth-icon {
    position: absolute;
    top: 14px;
    left: 4px;
    width: 8px;
    height: 8px;
}

.roster-faction-name {
    margin: 2px;
    padding: 2px;
}

.roster-faction-glowable:hover .roster-faction-name {
    text-decoration: underline;
}

.roster-faction-glowable:hover {
    background: #000;
    cursor: pointer;
}

.roster-faction-state {
    font-size: 80%;
    font-weight: normal;
    color: #000;
    padding: 2px;
    margin-left: 10px;
    text-shadow: none;
}

.roster-faction-percentage .change {
    font-size: 80%;
}
