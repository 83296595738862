.board {
    position: relative;
    float: left;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    overflow: auto;
    padding: 30px;
}

.layer {
    position: relative;
    float: left;
    min-width: 100%;
}

.layer-separator {
    border: none;
    background: none;
    position: relative;
    float: left;
    width: 100%;
    height: 1px;
    margin: 0px;
    padding: 0px;
}