.clock {
    position: absolute;
    bottom: 30px;
    left: 10px;
    right: 10px;
}

.clock p {
    margin: 0px 20px 0px 20px;
    padding: 0px;
    width: calc(100% - 40px);
    text-align: center;
}

.clock p.subtext {
    font-size: 80%;
    margin-top: 10px;
}