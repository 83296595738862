.stock-market {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    height: calc(100% - 100px);
    padding: 20px;
    padding-top: 20px;
    margin-top: 20px;
    background: #111;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.6);
    animation-name: flyIn;
    animation-duration: 0.5s;
    overflow-y: auto;
}

.stock-market-table {
    position: relative;
    float: left;
    width: 100%;
    padding: 0px;
    
}

.stock-market-table .first-column {
    padding-right: 10px;
    text-align: right;
}

.stock-market-table .growth-icon {
    width: 8px;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
}

@media screen and (orientation:landscape) and (min-width: 800px) {

    .stock-market {
        width: 700px;
        margin-left: calc(50% - 350px);
        padding-top: 40px;
    }

    .stock-market-table {
        padding: 0px 40px;
    }
}

@keyframes flyIn {
    0% {
        opacity: 0;
        transform: scale(0.2, 0.2) translateX(-200px) translateY(-100px)
    }
    100% {
        opacity: 1;
        transform: scale(1, 1) translateX(0px) translateY(0px)
    }
}