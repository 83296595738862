.station-list {
    position: relative;
    float: left;
    margin: 0px;
    margin-top: 2px;
    list-style-type: none;
}

.station-list-item {
    padding: 0px;
    height: 24px;
}

.station-list .subtext {
    font-size: 80%;
}

.station-list-icon-container {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.station-list-icon {
    width: 100%;
    height: 100%;
}

.station-list-name {
    position: relative;
    float: left;
    margin: 0px;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.station-list-at-stake {
    padding: 4px;
    font-size: 80%;
    margin-left: 10px;
    color: #111;
}

.station-list-carrier-link:hover .station-list-name {
    text-decoration: inherit;
}

.station-list-carrier-link:hover {
    cursor: pointer;
    text-decoration: underline;
}
