.navigator {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.navigator-logo {
    position: relative;
    float: left;
    width: 32px;
    margin: 0px 20px 0px 40px;
    height: auto;
    display: none;
}

.navigator-app-title {
    position: relative;
    float: left;
    margin: 7px 60px 0px 0px;
    padding: 0px;
    display: none;
}

.navigator-button {
    position: relative;
    float: left;
    width: 50%;
    padding-left: 50px;
    height: 100%;
    line-height: 100%;
    vertical-align: middle;
    font-size: 100%;
    text-align: left;
}

.navigator-button-icon, .navigator-button-icon-dark {
    position: absolute;
    top: 5px;
    left: 10px;
    height: 30px;
    width: auto;
}

.navigator-button-icon-dark {
    display: none;
}

.selected .navigator-button-icon-dark, .navigator-button:hover .navigator-button-icon-dark {
    display: block;
}

@media screen and (orientation:landscape) and (min-width: 1000px) {

    .navigator-logo, .navigator-app-title {
        display: block;
    }

    .navigator-button {
        width: initial;
        padding-left: 38px;
        padding-right: 20px;
    }

    .navigator-button-icon, .navigator-button-icon-dark {
        top: 5px;
        left: 10px;
        height: 20px;
    }
}