.app {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
}

.app-navigator-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
}

.app-router-target {
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

@media screen and (orientation:landscape) and (min-width: 1000px) {

    .app-navigator-container {
        height: 30px;
    }
    
    .app-router-target {
        top: 30px;
    }
}
