.error-message {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0px;
    padding: 30px;
    color: #FF2121;
    animation-name: lifecycle;
    animation-iteration-count: initial;
    text-align: center;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

@keyframes lifecycle {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}