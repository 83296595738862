.system-tile {
    position: relative;
    float: left;
    text-align: center;
    width: 114px;
    height: 64px;
    margin: 5px;
    padding: 3px;
    background-color: #141414;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    transition: background 0.2s, transform 0.2s;
    cursor: pointer;
    z-index: 1;
    animation-name: flyIn;
    animation-duration: 1s;
}

.system-tile:hover {
    transform: scale(1.2);
    background:#181818;
    z-index: 2;
}

.system-tile.unfocus {
    opacity: 0.2;
}

.system-tile-star {
    width: 20px;
    height: 20px;
    animation-name: glow;
    animation-iteration-count: infinite;
}

.system-tile-star.slow {
    animation-duration: 3s;
}

.system-tile-star.normal {
    animation-duration: 2s;
}

.system-tile-star.fast {
    animation-duration: 1s;
}

.system-tile-label {
    position: relative;
    float: left;
    width: calc(100% - 6px);
    margin: 0px;
    padding: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.system-tile:hover .system-tile-label {
    color: #000;
}

.system-tile-influence {
    position: relative;
    float: left;
    height: 2px;
}

.system-tile:hover .system-tile-influence {
    background: #000;
}

.system-tile-influence-growth-icon {
    position: absolute;
    bottom: 7px;
    left: 7px;
    width: 8px;
    height: 8px;
}

.system-alert {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    pointer-events: none;
}

.system-alert-exclamation {
    position: relative;
    float: right;
    margin: 6px 6px 0px 0px;
    padding: 0px;
    width: initial;
    animation-name: glow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.system-alert-text {
    position: absolute;
    height: 12px;
    line-height: 12px;
    vertical-align: middle;
    margin: 0px;
    padding: 2px;
    left: 0px;
    right: 0px;
    background: #FFF;
    color: #000;
    text-shadow: none;
    font-size: 60%;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.system-alert-text.top {
    top: -16px;
}

.system-alert-text.bottom {
    bottom: -16px;
}

.system-tile-carrier {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
}

.system-tile-carrier-icon {
    width: 100%;
    height: 100%;
}

@keyframes flyIn {
    0% {
        opacity: 0;
        transform: scale(0.2, 0.2) translateX(-200px) translateY(-100px)
    }
    100% {
        opacity: 1;
        transform: scale(1, 1) translateX(0px) translateY(0px)
    }
}

@keyframes glow {
    0% {
        opacity: 1;
        transform: scale(1.2);
    }
    50% {
        opacity: 0.6;
        transform: scale(1.0);
    }
    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}