.faction-stats {
    position: relative;
    float: left;
    width: calc(100% - 20px);
    padding: 10px;
    padding-top: 10px;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-name: fadeIn;
    animation-duration: 0.5s;
}

.faction-stats-list {
    margin-top: 20px;
}

.faction-stats-list li {
    position: relative;
    float: left;
    width: calc(100% - 10px);
}

.faction-label {
    margin-top: 20px;
    margin-bottom: 20px;
}

.faction-home-system:hover {
    text-decoration: underline;
    cursor: pointer;
}