.loader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    animation-name: backgroundFadeIn;
    animation-duration: 0.5s;
    z-index: 90;
}

.loader-light-logo, .loader-dark-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-position: center;
    background-repeat: no-repeat;
}

.loader-light-logo {
    animation-name: fadeIn;
    animation-duration: 0.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes backgroundFadeIn {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.6);
    }
}